import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Text, Icon, Container, Image } from "@atoms";
import { MobileHeader, HeaderSearch, SubNav, LocaleSwitcher } from "@molecules";
import { AppLink } from "@base";
import { useHeaderData } from "@staticQueries";
import { useAppState } from "@state/state";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Header = ({ lang }) => {
  const [{ layout }, dispatch] = useAppState();
  const data = useHeaderData();
  const { logo, usa, telephone, dealers, center, right } = data[lang || "en"];
  const homeUrl = useRef();

  // Scrolling header
  const stickyHeader = useRef();
  const tl = useRef();
  const [direction, setDirection] = useState("up");
  let oldScrollY = 0;

  // Mobile nav triggers
  const { showNav } = layout;

  useEffect(() => {
    // Scrolling header animation
    tl.current = gsap
      .timeline({ paused: true })
      .to(stickyHeader.current, { y: 0 })
      .to(stickyHeader.current, {
        y: -200,
        ease: "power3.inOut",
        duration: 0.6,
      });
  }, []);

  const scroll = e => {
    if (stickyHeader.current) {
      if (window.scrollY > stickyHeader.current.clientHeight) {
        if (window.scrollY > oldScrollY) {
          setDirection("down");
        } else {
          setDirection("up");
        }

        requestAnimationFrame(() => {
          oldScrollY = window.scrollY;
        });
      } else {
        setDirection("up");
      }
    }
  };

  useEffect(() => {
    if (direction === "up") {
      tl.current.reverse();
    } else {
      tl.current.play();
    }
  }, [direction]);

  useEffect(() => {
    window.addEventListener("scroll", scroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", scroll, { passive: true });
    };
  }, []);

  useEffect(() => {
    homeUrl.current = lang === "en" ? `/` : `/${lang}`;
  }, [lang]);

  return (
    <header
      className="fixed print:hidden w-full bg-white z-40 shadow-md flex flex-col justify-between"
      ref={stickyHeader}
    >
      <div className="text-white bg-dark-blue">
        <Container variant="xl">
          <div className="flex flex-wrap justify-center xs:justify-between items-center">
            <div className="flex flex-row py-2">
              {usa && (
                <AppLink to={usa?.url} className="flex items-center mr-4">
                  <Icon
                    name="flag"
                    className="w-5 h-auto flex-shrink-0 mr-1.5"
                  />
                  <Text variant="xs" className="!inline-block ml-0.5">
                    {usa?.text}
                  </Text>
                </AppLink>
              )}

              {telephone && (
                <AppLink to={telephone?.url} className="flex items-center">
                  <Icon
                    name="phone"
                    className="w-3.5 md:w-3 h-auto flex-shrink-0 mr-1.5"
                  />
                  <Text variant="xs" className="!inline-block ml-0.5">
                    {telephone?.text}
                  </Text>
                </AppLink>
              )}
            </div>

            <div className="ml-4 py-2">
              {dealers && (
                <AppLink to={dealers?.url} className="flex items-center">
                  <Icon
                    name="mapMarker"
                    className="w-4 md:w-3 h-auto flex-shrink-0 mr-1.5"
                  />
                  <Text variant="xs" className="!inline-block">
                    {dealers?.text}
                  </Text>
                </AppLink>
              )}
            </div>
          </div>
        </Container>
      </div>

      <nav className="py-4 md:py-0 h-full flex items-center">
        <Container
          variant="xl"
          className="flex justify-between items-center h-full"
        >
          <div>
            <AppLink to={homeUrl.current} className="group inline-block w-full">
              {!logo && (
                <Icon
                  name="logo"
                  className="w-28 lg:w-[128px] transition group-hover:text-blue"
                />
              )}
              {logo && <Image {...logo} />}
            </AppLink>
          </div>

          {center && (
            <ul className="hidden md:flex flex-row items-stretch">
              {center?.map((link, i) => {
                return (
                  <li key={`header-center--${i + 1}`}>
                    <SubNav {...link} />
                  </li>
                );
              })}
            </ul>
          )}

          {right && (
            <div className="flex relative items-center">
              <ul className="hidden md:flex space-x-8 mr-20">
                {right?.map((link, i) => {
                  return (
                    <li key={`header-right--${i + 1}`}>
                      <AppLink to={link?.url}>
                        <Text variant="sm">{link?.text}</Text>
                      </AppLink>
                    </li>
                  );
                })}
              </ul>

              <LocaleSwitcher />
              <HeaderSearch />

              {/* Hamburger icon button */}
              <button
                type="button"
                arial-label="Open menu"
                className="block md:hidden ml-6"
                onClick={() => {
                  dispatch({
                    type: !showNav ? "showNav" : "hideNav",
                  });
                }}
              >
                <div className="w-8 h-8 flex items-center justify-center relative">
                  <div
                    className={classNames(
                      "w-full h-[3px] bg-black transition-all duration-300 origin-center absolute",
                      { "rotate-45": showNav, "-translate-y-2.5": !showNav }
                    )}
                  />
                  <div
                    className={classNames(
                      "w-full h-[3px] bg-black transition-all duration-300 origin-center absolute",
                      { "opacity-0 w-0": showNav }
                    )}
                  />
                  <div
                    className={classNames(
                      "w-full h-[3px] bg-black transition duration-300 origin-center absolute",
                      { "-rotate-45": showNav, "translate-y-2.5": !showNav }
                    )}
                  />
                </div>
                <Text variant="2xs" className="text-blue uppercase font-bold">
                  Menu
                </Text>
              </button>
            </div>
          )}
        </Container>
      </nav>
      <MobileHeader />
    </header>
  );
};

Header.defaultProps = {};

Header.propTypes = {};

export default Header;
